import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router";
import { useParams, useLocation } from "react-router-dom";

import { CaseStatus, ResponseStatus, METATAGS } from "../../constants";
import FilingCompleted from "./FilingCompleted";
import POAApplication from "./POAApplication";
import POAApplicationCompleted from "./POAApplicationCompleted";
import Publication from "./Publication";
import PublicationAwaiting from "./PublicationAwaiting";
import Registration from "./Registration";
import RegistrationAwaiting from "./RegistrationAwaiting";
import TMCRDownload from "./TMCRDownload";
import TMCRAwaiting from "./TMCRAwaiting";
import { GetCaseById } from "api/api.service";
import { Loader } from "components/Loader";
import "./CaseManagement.scss";
import { CaseManagementHeader } from "components/CaseManagementHeader";
import { findOutAccordianId } from "./utils";
import { accordionStatus, caseStatus, responseStatus, stepTitle, stepsNames } from "./Constants";
import { Socket, SocketTypes } from "../../utils/socketUtils";
import { store } from "store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

let dataObj = {};

const Details = () => {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const userId = store?.getState()?.userAuth?.user?.id;
    const [open, setOpen] = useState(["11"]);
    const [data, setData] = useState({});
    const [isFormTwoVisible, setIsFormTwoVisible] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isRefetchingRequired, setIsRefetchingRequired] = useState(false);
    const [isSubmittingStep, setIsSubmittingStep] = useState(false);

    // updated steps submission status
    const stepSubmissionStepUpdater = () => setIsSubmittingStep(!isSubmittingStep);
    // refetch steps API handler
    const refetchStepsAPIHandler = () => {
        setIsRefetchingRequired(!isRefetchingRequired);
    };

    const nextStepHandlerForOtherSteps = (accordianId, data) => {
        setOpen([accordianId]);
        if (data) dataObj.expiryDate = data?.feeDeadline;
        setIsFormTwoVisible(true);
    };

    // refetching the data from API handling
    useEffect(async () => {
        try {
            setIsFetchingData(true);
            let res = await GetCaseById(params?.id);
            let stepStatus = ResponseStatus.getDisplayTextKey(res?.trademarkStepsStatus);
            findOutAccordianId(res?.trademarkStep, stepStatus, setOpen);
            setData(res);
        } finally {
            setIsFetchingData(false);
            setIsFormTwoVisible(false);
            setIsSubmittingStep(false);
        }
    }, [isRefetchingRequired, params?.id, location?.state?.isSameId]);

    // calling join socket event for creation of chat on start chat
    const handleStartChatClick = () => {
        Socket.onChatStarted(Number(params?.id), (data) => {
            if (data?.chatId) {
                history.push(`/chats/${data?.chatId}`);
            }
        });
    };

    const findIfChatButtonExists = () => {
        const isData = !isFetchingData && Object.keys(data).length > 0;
        const isTrademarkStatusFileDownloaded =
            CaseStatus.getDisplayTextKey(data?.trademarkStatus) === caseStatus.FILE_DOWNLOADED;
        const isResponseStatusCompleted =
            ResponseStatus.getDisplayTextKey(data?.trademarkStepsStatus) ===
            responseStatus.COMPLETED;
        const isResponseStatusDispatched =
            ResponseStatus.getDisplayTextKey(data?.trademarkStepsStatus) ===
            responseStatus.DISPATCHED;
        const isResponseStatusCompletedOrDispatched =
            isResponseStatusCompleted || isResponseStatusDispatched;
        if (isData && isTrademarkStatusFileDownloaded && isResponseStatusCompletedOrDispatched) {
            return false;
        }
        return true;
    };

    return (
        <>
            <MetaTags>
                <title>{METATAGS.CASE_DETAILS}</title>
            </MetaTags>
            {isFetchingData ? (
                <>
                    <Loader />
                </>
            ) : null}
            {!isFetchingData && Object.keys(data).length > 0 && (
                <div className="case-detail-container">
                    {/* <CaseManagementHeader data={data} onButtonClick={handleStartChatClick} isChatButton={findIfChatButtonExists()}/> */}
                    <CaseManagementHeader data={data} onButtonClick={handleStartChatClick} />
                    {/** if all steps are completed */}
                    {data?.trademarkStep === stepsNames.TMCR_DOWNLOAD &&
                        CaseStatus.getDisplayTextKey(data?.trademarkStatus) ===
                            caseStatus.FILE_DOWNLOADED && (
                            <>
                                <FilingCompleted
                                    defaultOpenAccord={open}
                                    data_description={data?.filing}
                                    isCompleted={true}
                                    processNO={"01"}
                                    targetId={"211"}
                                    title={stepTitle.DOCUMENT_SUBMISSION}
                                />
                                <POAApplicationCompleted
                                    defaultOpenAccord={open}
                                    title={stepTitle.E_SIGNATURE}
                                    isCompleted={true}
                                    targetId={"02"}
                                    isPA={true}
                                    processNO={"02"}
                                    data_description={data?.pOAApplication}
                                />
                                <POAApplicationCompleted
                                    defaultOpenAccord={open}
                                    title={stepTitle.PUBLICATION}
                                    isCompleted={true}
                                    targetId={"03"}
                                    isPA={false}
                                    processNO={"03"}
                                    data_description={data?.publication}
                                />
                                <POAApplicationCompleted
                                    defaultOpenAccord={open}
                                    title={stepTitle.REGISTRATION}
                                    isCompleted={true}
                                    targetId={"04"}
                                    isPA={false}
                                    processNO={"04"}
                                    data_description={data?.registration}
                                />
                                {ResponseStatus.getDisplayTextKey(data?.trademarkStepsStatus) ===
                                responseStatus.PENDING ? (
                                    <POAApplicationCompleted
                                        defaultOpenAccord={open}
                                        title={stepTitle.TMCR_DOWNLOAD}
                                        fileDownload={true}
                                        isCompleted={true}
                                        targetId={"05"}
                                        isPA={false}
                                        processNO={"05"}
                                        deliveryStatus={data?.registration?.deliveryStatus}
                                        data_description={data?.tmcrDownload}
                                        status={accordionStatus.OPEN}
                                        isRefetchingRequired={setIsRefetchingRequired}
                                        isDispatch={
                                            data?.tmcrDownload?.trademarkStepsStatus ===
                                            "Dispatched"
                                                ? false
                                                : true
                                        }
                                    />
                                ) : (
                                    <POAApplicationCompleted
                                        defaultOpenAccord={open}
                                        title={stepTitle.TMCR_DOWNLOAD}
                                        fileDownload={true}
                                        isCompleted={true}
                                        targetId={"06"}
                                        isPA={false}
                                        processNO={"05"}
                                        deliveryStatus={data?.registration?.deliveryStatus}
                                        data_description={data?.tmcrDownload}
                                        status={
                                            data?.registration?.deliveryStatus &&
                                            data?.tmcrDownload?.trademarkStepsStatus ===
                                                "PendingCustomer"
                                                ? accordionStatus.OPEN
                                                : undefined
                                        }
                                    />
                                )}
                            </>
                        )}

                    {/** handling document submission and e-signature steps submission */}
                    {data?.trademarkStep === stepsNames.DOCUMENT_SUBMISSION &&
                        CaseStatus.getDisplayTextKey(data?.trademarkStatus) === caseStatus.OPEN && (
                            <>
                                <FilingCompleted
                                    defaultOpenAccord={open}
                                    data_description={data?.filing}
                                    isCompleted={false}
                                    targetId={"11"}
                                    processNO={"01"}
                                    nextStepTargetId={"12"}
                                    isSubmittingStep={isSubmittingStep}
                                    title={stepTitle.DOCUMENT_SUBMISSION}
                                    nextStepHandler={nextStepHandlerForOtherSteps}
                                    isFormTwoVisible={isFormTwoVisible}
                                    refetchStepsAPIHandler={refetchStepsAPIHandler}
                                />
                                {isFormTwoVisible ? (
                                    <>
                                        <POAApplication
                                            defaultOpenAccord={open}
                                            title={stepTitle.E_SIGNATURE}
                                            targetId={"12"}
                                            isPA={true}
                                            processNO={"02"}
                                            dataObj={dataObj}
                                            refetchStepsAPIHandler={refetchStepsAPIHandler}
                                            stepSubmissionStepUpdater={stepSubmissionStepUpdater}
                                        />
                                    </>
                                ) : null}
                            </>
                        )}

                    {/** handling document submission and e-signature steps rejection */}
                    {data?.trademarkStep === stepsNames.DOCUMENT_SUBMISSION &&
                    CaseStatus.getDisplayTextKey(data?.trademarkStatus) === caseStatus.CLOSED &&
                    data?.trademarkStepsStatus === responseStatus.REJECTED ? (
                        <>
                            <FilingCompleted
                                defaultOpenAccord={open}
                                data_description={data?.filing}
                                isCompleted={true}
                                processNO={"01"}
                                targetId={"21"}
                                isReject={true}
                                rejectionReason={data?.reason}
                                title={stepTitle.DOCUMENT_SUBMISSION}
                            />
                        </>
                    ) : null}

                    {/** handling publication step submission, awaiting and rejection */}
                    {data?.trademarkStep === stepsNames.E_SIGNATURE && (
                        <>
                            <FilingCompleted
                                defaultOpenAccord={open}
                                data_description={data?.filing}
                                isCompleted={true}
                                processNO={"01"}
                                targetId={"22"}
                                title={stepTitle.DOCUMENT_SUBMISSION}
                            />
                            {CaseStatus.getDisplayTextKey(data?.trademarkStatus) ===
                            caseStatus.OPEN ? (
                                ResponseStatus.getDisplayTextKey(data?.trademarkStepsStatus) ===
                                responseStatus.AWAITING ? (
                                    <>
                                        <POAApplicationCompleted
                                            defaultOpenAccord={open}
                                            title={stepTitle.E_SIGNATURE}
                                            status={accordionStatus.OPEN}
                                            isCompleted={true}
                                            targetId={"23"}
                                            isAwaiting={true}
                                            isPA={true}
                                            processNO={"02"}
                                            data_description={data?.pOAApplication}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <POAApplicationCompleted
                                            defaultOpenAccord={open}
                                            title={stepTitle.E_SIGNATURE}
                                            status={accordionStatus.OPEN}
                                            isCompleted={false}
                                            targetId={"24"}
                                            isPA={true}
                                            processNO={"02"}
                                            nextStepTargetId={"25"}
                                            nextStepHandler={nextStepHandlerForOtherSteps}
                                            isFormTwoVisible={isFormTwoVisible}
                                            data_description={data?.pOAApplication}
                                            refetchStepsAPIHandler={refetchStepsAPIHandler}
                                            isSubmittingStep={isSubmittingStep}
                                        />
                                        {isFormTwoVisible ? (
                                            <Publication
                                                isAwaiting={true}
                                                defaultOpenAccord={open}
                                                title={stepTitle.PUBLICATION}
                                                targetId={"25"}
                                                processNO={"03"}
                                                data_description={data?.pOAApplication}
                                                refetchStepsAPIHandler={refetchStepsAPIHandler}
                                                stepSubmissionStepUpdater={
                                                    stepSubmissionStepUpdater
                                                }
                                            />
                                        ) : null}
                                    </>
                                )
                            ) : (
                                <POAApplicationCompleted
                                    defaultOpenAccord={open}
                                    title={stepTitle.E_SIGNATURE}
                                    status={accordionStatus.REJECTED}
                                    isCompleted={true}
                                    targetId={"26"}
                                    isPA={true}
                                    processNO={"02"}
                                    isReject={true}
                                    rejectionReason={data?.reason}
                                    data_description={data?.pOAApplication}
                                />
                            )}
                        </>
                    )}

                    {/** handling registration step submission, awaiting and rejection */}
                    {data?.trademarkStep === stepsNames.PUBLICATION && (
                        <>
                            <FilingCompleted
                                defaultOpenAccord={open}
                                data_description={data?.filing}
                                targetId={"31"}
                                isCompleted={true}
                                processNO={"01"}
                                title={stepTitle.DOCUMENT_SUBMISSION}
                            />
                            <POAApplicationCompleted
                                defaultOpenAccord={open}
                                title={stepTitle.E_SIGNATURE}
                                isCompleted={true}
                                targetId={"32"}
                                isPA={true}
                                processNO={"02"}
                                data_description={data?.pOAApplication}
                            />
                            {CaseStatus.getDisplayTextKey(data?.trademarkStatus) ===
                            caseStatus.OPEN ? (
                                <>
                                    {ResponseStatus.getDisplayTextKey(
                                        data?.trademarkStepsStatus
                                    ) === responseStatus.AWAITING ? (
                                        <>
                                            <PublicationAwaiting
                                                defaultOpenAccord={open}
                                                title={stepTitle.PUBLICATION}
                                                targetId={"33"}
                                                isPA={false}
                                                processNO={"03"}
                                                data_description={data?.publication}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <POAApplicationCompleted
                                                defaultOpenAccord={open}
                                                title={stepTitle?.PUBLICATION}
                                                status={accordionStatus.OPEN}
                                                targetId={"34"}
                                                isCompleted={false}
                                                isPA={false}
                                                processNO={"03"}
                                                nextStepTargetId={"35"}
                                                data_description={data?.publication}
                                                nextStepHandler={nextStepHandlerForOtherSteps}
                                                isFormTwoVisible={isFormTwoVisible}
                                                refetchStepsAPIHandler={refetchStepsAPIHandler}
                                                isSubmittingStep={isSubmittingStep}
                                            />
                                            {isFormTwoVisible ? (
                                                <Registration
                                                    isAwaiting={true}
                                                    defaultOpenAccord={open}
                                                    title={stepTitle.REGISTRATION}
                                                    targetId={"35"}
                                                    processNO={"04"}
                                                    data_description={data?.publication}
                                                    refetchStepsAPIHandler={refetchStepsAPIHandler}
                                                    stepSubmissionStepUpdater={
                                                        stepSubmissionStepUpdater
                                                    }
                                                />
                                            ) : null}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <POAApplicationCompleted
                                        targetId={"36"}
                                        isReject={true}
                                        processNO={"03"}
                                        defaultOpenAccord={open}
                                        title={stepTitle.PUBLICATION}
                                        status={accordionStatus.REJECTED}
                                        isCompleted={true}
                                        rejectionReason={data?.reason}
                                        data_description={data?.publication}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {/** handling TMCRD DOWNLOAD step submission, awaiting and rejection */}
                    {data?.trademarkStep === stepsNames.REGISTRATION &&
                        CaseStatus.getDisplayTextKey(data?.trademarkStatus) !==
                            caseStatus.FILE_DOWNLOADED && (
                            <>
                                <FilingCompleted
                                    defaultOpenAccord={open}
                                    data_description={data?.filing}
                                    targetId={"41"}
                                    isCompleted={true}
                                    processNO={"01"}
                                    title={stepTitle.DOCUMENT_SUBMISSION}
                                />
                                <POAApplicationCompleted
                                    defaultOpenAccord={open}
                                    data_description={data?.pOAApplication}
                                    title={stepTitle.E_SIGNATURE}
                                    isCompleted={true}
                                    targetId={"42"}
                                    isPA={true}
                                    processNO={"02"}
                                />
                                <POAApplicationCompleted
                                    defaultOpenAccord={open}
                                    data_description={data?.publication}
                                    title={stepTitle.PUBLICATION}
                                    isCompleted={true}
                                    targetId={"43"}
                                    isPA={false}
                                    processNO={"03"}
                                />
                                {CaseStatus.getDisplayTextKey(data?.trademarkStatus) ===
                                caseStatus.OPEN ? (
                                    <>
                                        {ResponseStatus.getDisplayTextKey(
                                            data?.trademarkStepsStatus
                                        ) === responseStatus.AWAITING ? (
                                            <>
                                                <RegistrationAwaiting
                                                    defaultOpenAccord={open}
                                                    title={stepTitle.REGISTRATION}
                                                    targetId={"44"}
                                                    isPA={false}
                                                    processNO={"04"}
                                                    data_description={data?.registration}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <POAApplicationCompleted
                                                    defaultOpenAccord={open}
                                                    data_description={data?.registration}
                                                    title={stepTitle.REGISTRATION}
                                                    status={accordionStatus.OPEN}
                                                    isCompleted={false}
                                                    targetId={"45"}
                                                    isPA={false}
                                                    processNO={"04"}
                                                    nextStepTargetId={"46"}
                                                    nextStepHandler={nextStepHandlerForOtherSteps}
                                                    isFormTwoVisible={isFormTwoVisible}
                                                    refetchStepsAPIHandler={refetchStepsAPIHandler}
                                                    isSubmittingStep={isSubmittingStep}
                                                />
                                                {isFormTwoVisible ? (
                                                    <TMCRDownload
                                                        isAwaiting={true}
                                                        defaultOpenAccord={open}
                                                        title={stepTitle.TMCR_DOWNLOAD}
                                                        targetId={"46"}
                                                        processNO={"05"}
                                                        data_description={data?.registration}
                                                        refetchStepsAPIHandler={
                                                            refetchStepsAPIHandler
                                                        }
                                                        stepSubmissionStepUpdater={
                                                            stepSubmissionStepUpdater
                                                        }
                                                    />
                                                ) : null}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <POAApplicationCompleted
                                            defaultOpenAccord={open}
                                            status={accordionStatus.REJECTED}
                                            data_description={data?.registration}
                                            rejectionReason={data?.reason}
                                            title={stepTitle.REGISTRATION}
                                            targetId={"47"}
                                            isCompleted={true}
                                            isReject={true}
                                            processNO={"04"}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    {/** handling TMCRD DOWNLOAD step submission, awaiting and rejection */}
                    {data?.trademarkStep === stepsNames.TMCR_DOWNLOAD &&
                        CaseStatus.getDisplayTextKey(data?.trademarkStatus) !==
                            caseStatus.FILE_DOWNLOADED && (
                            <>
                                <FilingCompleted
                                    defaultOpenAccord={open}
                                    data_description={data?.filing}
                                    targetId={"51"}
                                    isCompleted={true}
                                    processNO={"01"}
                                    title={stepTitle.DOCUMENT_SUBMISSION}
                                />
                                <POAApplicationCompleted
                                    defaultOpenAccord={open}
                                    data_description={data?.pOAApplication}
                                    title={stepTitle.E_SIGNATURE}
                                    isCompleted={true}
                                    targetId={"52"}
                                    isPA={true}
                                    processNO={"02"}
                                />
                                <POAApplicationCompleted
                                    defaultOpenAccord={open}
                                    data_description={data?.publication}
                                    title={stepTitle.PUBLICATION}
                                    isCompleted={true}
                                    targetId={"53"}
                                    isPA={false}
                                    processNO={"03"}
                                />
                                <POAApplicationCompleted
                                    defaultOpenAccord={open}
                                    data_description={data?.registration}
                                    title={stepTitle.REGISTRATION}
                                    isCompleted={true}
                                    targetId={"54"}
                                    isPA={false}
                                    processNO={"04"}
                                />
                                {CaseStatus.getDisplayTextKey(data?.trademarkStatus) ===
                                caseStatus.OPEN ? (
                                    <>
                                        {ResponseStatus.getDisplayTextKey(
                                            data?.trademarkStepsStatus
                                        ) === responseStatus.AWAITING ? (
                                            <>
                                                <TMCRAwaiting
                                                    defaultOpenAccord={open}
                                                    title={stepTitle.TMCR_DOWNLOAD}
                                                    targetId={"55"}
                                                    isPA={false}
                                                    processNO={"05"}
                                                    data_description={data?.tmcrDownload}
                                                    address={data?.registration?.address}
                                                    deliveryStatus={
                                                        data?.registration?.deliveryStatus
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <POAApplicationCompleted
                                                    defaultOpenAccord={open}
                                                    data_description={data?.registration}
                                                    title={stepTitle.REGISTRATION}
                                                    status={accordionStatus.OPEN}
                                                    isCompleted={false}
                                                    targetId={"56"}
                                                    isPA={false}
                                                    processNO={"04"}
                                                    nextStepTargetId={"57"}
                                                    nextStepHandler={nextStepHandlerForOtherSteps}
                                                    isFormTwoVisible={isFormTwoVisible}
                                                    refetchStepsAPIHandler={refetchStepsAPIHandler}
                                                    isSubmittingStep={isSubmittingStep}
                                                />
                                                {isFormTwoVisible ? (
                                                    <TMCRDownload
                                                        isAwaiting={true}
                                                        defaultOpenAccord={open}
                                                        title={stepTitle.TMCR_DOWNLOAD}
                                                        targetId={"57"}
                                                        processNO={"05"}
                                                        data_description={data?.registration}
                                                        refetchStepsAPIHandler={
                                                            refetchStepsAPIHandler
                                                        }
                                                        stepSubmissionStepUpdater={
                                                            stepSubmissionStepUpdater
                                                        }
                                                    />
                                                ) : null}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <POAApplicationCompleted
                                            defaultOpenAccord={open}
                                            status={accordionStatus.REJECTED}
                                            data_description={data?.registration}
                                            rejectionReason={data?.reason}
                                            title={stepTitle.REGISTRATION}
                                            targetId={"58"}
                                            isCompleted={true}
                                            isReject={true}
                                            processNO={"04"}
                                        />
                                    </>
                                )}
                            </>
                        )}
                </div>
            )}
        </>
    );
};

export default withRouter(Details);
